







import Vue, { PropType } from "vue";
import KaijuAddFundsForm from "./KaijuAddFundsForm.vue";
import { FundsFormValues } from "./KaijuAddFundsForm.vue";
import Modal from "./Modal.vue";
export default Vue.extend({
  name: "KaijuAddFundsModal",
  components: {
    KaijuAddFundsForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true },
    account: { type: Object as PropType<AccountStoreEntry>, required: true },
    accountId: { type: String, required: true },
    userId: { type: String, required: true }
  },
  computed: {
    formValues(): FundsFormValues {
      return {
        funds: 0
      };
    }
  },
  methods: {
    async addFundsSubmission({ funds }: { funds: number }): Promise<void> {
      const fundsInCents = funds * 100;
      await this.$store.direct.dispatch.addFundsToAccount({
        accountId: this.accountId,
        originType: "admin-portal",
        id: this.userId,
        fundsToAdd: fundsInCents,
        currency: "USD"
      });
      this.closeModal();
    }
  }
});
