












import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "KaijuPayoutStateItem",
  components: {},
  props: {
    payoutState: { type: Object as PropType<Kaiju.PayoutState>, required: true }
  },
  data: () => ({
    payoutModalIsOpen: false
  }),
  computed: {
    listItemClass(): string {
      switch (this.payoutState.type) {
        case "COMPLETE":
          return "payout-state-list-item__type-green";
        case "CANCELLED":
        case "FAILED":
        case "REQUESTED_CANCELLATION":
          return "payout-state-list-item__type-red";
        case "INITIATED":
        case "PENDING":
        default:
          return "payout-state-list-item__type-default";
      }
    }
  }
});
