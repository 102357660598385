















import Vue from "vue";
import orderBy from "lodash/orderBy";
import ListFirestore from "@/components/ListFirestore.vue";
import KaijuFundsHistoryItem from "@/components/KaijuFundsHistoryItem.vue";
import { db } from "@/firebase";
export default Vue.extend({
  name: "KaijuFundsHistoryList",
  components: {
    ListFirestore,
    KaijuFundsHistoryItem
  },
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    }
  },
  methods: {
    getKaijuFundsHistory() {
      return db
        .collection("accounts")
        .doc(this.accountId)
        .collection("kaijuData")
        .doc("transactions")
        .collection("fundsHistory")
        .orderBy("date", "desc");
    },
    orderList(items: Kaiju.FundsHistoryInfo[]) {
      return orderBy(Object.values(items), "date", "desc");
    }
  }
});
