





































import Vue, { PropType } from "vue";
import KaijuPayoutModal from "@/components/KaijuPayoutModal.vue";
export default Vue.extend({
  name: "KaijuPayoutItem",
  components: {
    KaijuPayoutModal
  },
  props: {
    payoutData: { type: Object as PropType<Kaiju.PayoutData>, required: true }
  },
  data: () => ({
    payoutModalIsOpen: false
  }),
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    },
    currentUserId(): string | null {
      return this.$store.direct.state.currentUser.uid;
    }
  },
  methods: {
    onPayoutClick(): void {
      this.payoutModalIsOpen = true;
    }
  }
});
