















import Vue from "vue";
import orderBy from "lodash/orderBy";
import ListFirestore from "@/components/ListFirestore.vue";
import KaijuPayoutItem from "@/components/KaijuPayoutItem.vue";
import { db } from "@/firebase";
export default Vue.extend({
  name: "KaijuPayoutsList",
  components: {
    ListFirestore,
    KaijuPayoutItem
  },
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    }
  },
  methods: {
    getKaijuPayouts() {
      return db
        .collection("accounts")
        .doc(this.accountId)
        .collection("kaijuData")
        .doc("transactions")
        .collection("payouts")
        .orderBy("dateInitiated", "desc");
    },
    orderList(items: Kaiju.PayoutData[]) {
      return orderBy(Object.values(items), "dateInitiated", "desc");
    }
  }
});
