




















import Vue, { PropType } from "vue";
import ActionButton from "./ActionButton.vue";
import ErrorNotice from "./ErrorNotice.vue";
import NumberInput from "./NumberInput.vue";
export interface FundsFormValues {
  funds: number;
}
export function defaultFundsFormValues(): FundsFormValues {
  return {
    funds: 0
  };
}
export default Vue.extend({
  name: "KaijuAddFundsForm",
  components: {
    ActionButton,
    ErrorNotice,
    NumberInput
  },
  props: {
    type: { type: String, required: true },
    formValues: {
      type: Object as PropType<FundsFormValues>,
      default: () => defaultFundsFormValues()
    },
    onSubmit: {
      type: Function as PropType<(arg0: FundsFormValues) => Promise<void>>,
      required: true
    }
  },
  data() {
    return {
      funds: this.formValues.funds ?? 0,
      submitting: false,
      error: null as Error | null
    };
  },
  computed: {
    hasChanges(): boolean {
      const funds = this.funds;
      return funds !== this.formValues.funds;
    }
  },
  watch: {
    funds() {
      this.error = null;
    },
    referringUsers() {
      this.error = null;
    }
  },
  methods: {
    async submitAccountForm(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          const funds = this.funds;
          await this.onSubmit({ funds });
        } catch (error: unknown) {
          console.error(error);
          if (error instanceof Error) {
            this.error = error;
          } else {
            this.error = new Error(JSON.stringify(error));
          }
        }
        this.submitting = false;
      }
    }
  }
});
