














import Vue from "vue";
import orderBy from "lodash/orderBy";
import ListFirestore from "@/components/ListFirestore.vue";
import KaijuPayoutStateItem from "@/components/KaijuPayoutStateItem.vue";
import { db } from "@/firebase";
export default Vue.extend({
  name: "KaijuPayoutStateList",
  components: {
    ListFirestore,
    KaijuPayoutStateItem
  },
  props: {
    accountId: { type: String, required: true },
    payoutId: { type: String, required: true }
  },
  methods: {
    getKaijuPayouts() {
      return db
        .collection("accounts")
        .doc(this.accountId)
        .collection("kaijuData")
        .doc("transactions")
        .collection("payouts")
        .doc(this.payoutId)
        .collection("states")
        .orderBy("date", "desc");
    },
    orderList(items: Kaiju.PayoutState[]) {
      return orderBy(Object.values(items), "date", "desc");
    }
  }
});
