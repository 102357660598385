























import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "KaijuFundsHistoryItem",
  props: {
    fundsInfo: { type: Object as PropType<Kaiju.FundsHistoryInfo>, required: true }
  }
});
