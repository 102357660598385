

































































































































import Vue from "vue";
import ActionButton from "@/components/ActionButton.vue";
import KaijuAddFundsModal from "@/components/KaijuAddFundsModal.vue";
import KaijuFundsHistoryList from "@/components/KaijuFundsHistoryList.vue";
import KaijuPayoutsList from "@/components/KaijuPayoutsList.vue";
import { db } from "@/firebase";
import { defaultKaijuInfo } from "@/typings/defaults/kaiju";
export default Vue.extend({
  name: "AccountKaiju",
  components: {
    ActionButton,
    KaijuAddFundsModal,
    KaijuFundsHistoryList,
    KaijuPayoutsList
  },
  data: () => ({
    addFundsModalIsOpen: false,
    kaijuActionSubmitting: false,
    onViewFunds: true,
    onViewPayouts: false,
    onViewDeposits: false,
    error: null as Error | null
  }),
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    },
    account(): AccountStoreEntry | undefined {
      return this.accountId ? this.$store.direct.state.accounts.all[this.accountId] : undefined;
    },
    currentUserId(): string | null {
      return this.$store.direct.state.currentUser.uid;
    },
    kaijuInfo(): Kaiju.Info {
      return this.account?.kaijuInfo ?? defaultKaijuInfo();
    }
  },
  methods: {
    getAccountUsers() {
      return db
        .collection("accounts")
        .doc(this.accountId)
        .collection("users")
        .where("registered", "==", true)
        .orderBy("firstName", "asc");
    },
    async handleKaijuUpdate(
      status: "enroll" | "enable" | "disable" | "resume" | "pause"
    ): Promise<void> {
      if (this.kaijuActionSubmitting) return;
      this.kaijuActionSubmitting = true;
      const accountId = this.accountId ?? "";
      const userId = this.currentUserId ?? "";
      try {
        switch (status) {
          case "enroll":
            await this.$store.direct.dispatch.enrollInKaiju(accountId);
            break;
          case "enable":
            await this.$store.direct.dispatch.enableKaiju({ accountId, userId });
            break;
          case "disable":
            await this.$store.direct.dispatch.disableKaiju({ accountId, userId });
            break;
          case "resume":
            await this.$store.direct.dispatch.resumeKaiju({ accountId, userId });
            break;
          case "pause":
            await this.$store.direct.dispatch.pauseKaiju({ accountId, userId });
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          this.error = error;
        } else {
          this.error = new Error(error);
        }
      } finally {
        this.kaijuActionSubmitting = false;
      }
    },
    async enrollInKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("enroll");
    },
    async enableKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("enable");
    },
    async disableKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("disable");
    },
    async resumeKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("resume");
    },
    async pauseKaijuPay(): Promise<void> {
      await this.handleKaijuUpdate("pause");
    },
    viewFunds(): void {
      this.onViewDeposits = false;
      this.onViewPayouts = false;
      this.onViewFunds = true;
    },
    viewPayouts(): void {
      this.onViewDeposits = false;
      this.onViewFunds = false;
      this.onViewPayouts = true;
    },
    viewDeposits(): void {
      this.onViewPayouts = false;
      this.onViewFunds = false;
      this.onViewDeposits = true;
    }
  }
});
