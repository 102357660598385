















































import Vue, { PropType } from "vue";
import ActionButton from "@/components/ActionButton.vue";
import DeleteIcon from "@/icons/Delete.vue";
import KaijuPayoutStateList from "@/components/KaijuPayoutStateList.vue";
import Modal from "./Modal.vue";
export default Vue.extend({
  name: "KaijuPayoutModal",
  components: {
    ActionButton,
    DeleteIcon,
    KaijuPayoutStateList,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true },
    accountId: { type: String, required: true },
    userId: { type: String, required: true },
    payoutData: { type: Object as PropType<Kaiju.PayoutData>, required: true }
  },
  data: () => ({
    bonusPayment: null as BonusPayment | null,
    payoutId: null as string | null,
    referral: null as Referral | null,
    recipient: null as User | null,
    authorizor: null as User | null,
    cancelingPayment: false,
    error: null as Error | null
  }),
  computed: {
    recipientName(): string {
      const firstName = this.recipient?.firstName ?? null;
      const lastName = this.recipient?.lastName ?? null;
      return `${firstName?.trim() ?? ""} ${lastName?.trim() ?? ""}`.trim() || "Unnamed User";
    },
    authorizorName(): string {
      const firstName = this.authorizor?.firstName ?? null;
      const lastName = this.authorizor?.lastName ?? null;
      return `${firstName?.trim() ?? ""} ${lastName?.trim() ?? ""}`.trim() || "Unnamed User";
    },
    company(): string | null {
      return this.recipient?.company || null;
    },
    canRequestCancellation(): boolean {
      switch (this.payoutData.currentState) {
        case "INITIATED":
        case "PENDING":
          return true;
        case "CANCELLED":
        case "COMPLETE":
        case "FAILED":
        case "REQUESTED_CANCELLATION":
        default:
          return false;
      }
    }
  },
  watch: {
    bonusPayment: {
      immediate: true,
      handler() {
        void this.getBonusPaymentData();
      }
    },
    payoutId: {
      immediate: true,
      handler() {
        void this.getPayoutId();
      }
    },
    referral: {
      immediate: true,
      handler() {
        void this.getReferralData();
      }
    },
    recipient: {
      immediate: true,
      handler() {
        void this.getRecipientData();
      }
    },
    authorizor: {
      immediate: true,
      handler() {
        void this.getAuthorizorData();
      }
    }
  },
  methods: {
    async requestCancellation(): Promise<void> {
      if (this.payoutId === null || !this.canRequestCancellation) return;
      this.cancelingPayment = true;
      try {
        const currentUser = await this.$store.direct.dispatch.getUser({ userId: this.userId });
        const note =
          (currentUser?.firstName as string) +
          " " +
          (currentUser?.lastName as string) +
          " requested cancellation";
        if (this.payoutId.length > 0) {
          await this.$store.direct.dispatch.requestKaijiPayoutCancellation({
            accountId: this.accountId,
            payoutId: this.payoutId,
            note: note,
            userId: this.userId
          });
        }
      } catch (error) {
        console.error(error);
      }
      this.cancelingPayment = false;
    },
    async getBonusPaymentData(): Promise<void> {
      if (this.payoutData.bonusPaymentId.length === 0) return;
      const bp = await this.$store.direct.dispatch.getBonusPayment({
        bPaymentId: this.payoutData.bonusPaymentId
      });
      if (bp !== null) this.bonusPayment = bp;
    },
    async getPayoutId(): Promise<void> {
      if (this.bonusPayment === null) await this.getBonusPaymentData();
      this.payoutId = this.bonusPayment?.kaijuPayoutId ?? "";
    },
    async getReferralData(): Promise<void> {
      if (this.bonusPayment === null) await this.getBonusPaymentData();
      if (this.payoutData.referralId.length === 0) return;
      const refData = await this.$store.direct.dispatch.getReferral({
        referralId: this.payoutData.referralId
      });
      if (refData !== null) this.referral = refData;
    },
    async getRecipientData(): Promise<void> {
      if (this.bonusPayment === null) await this.getBonusPaymentData();
      if (this.payoutData.recipientId.length === 0) return;
      const refData = await this.$store.direct.dispatch.getUser({
        userId: this.payoutData.recipientId
      });
      if (refData !== null) this.recipient = refData;
    },
    async getAuthorizorData(): Promise<void> {
      if (this.bonusPayment === null) await this.getBonusPaymentData();
      const authorizorId = this.bonusPayment?.authorizedBy ?? "";
      if (authorizorId.length === 0) return;
      const refData = await this.$store.direct.dispatch.getUser({ userId: authorizorId });
      if (refData !== null) this.authorizor = refData;
    }
  }
});
